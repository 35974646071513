<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<el-alert title="操作提示" type="success" class="margin-bottom">
			<div>
				1. 标识“*”的选项为必填项，其余为选填项。<br />
				2. 请按提示栏信息进行商品添加。
			</div>
		</el-alert>
		<div class="box">
			<div class="form_box">
				<!-- {{form.permissions}} -->
				<el-form ref="form" :model="form" label-width="80px" v-loading="loading">
					<el-form-item label="选择套餐" prop="set_meal_id" :rules="rules.required">
						<el-select v-model="form.set_meal_id" placeholder="请选择">
							<el-option v-for="item in groups" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="规格名称" prop="name" :rules="rules.required">
						<el-input v-model="form.name" maxlength="30"></el-input>
					</el-form-item>
					<el-form-item label="价格" prop="price" :rules="rules.required">
						<el-input-number v-model="form.price" :precision="2" :step="10" :max="99999" :min="0"></el-input-number>
					</el-form-item>
					<el-form-item label="销售提成" prop="sales_commission" :rules="rules.required">
						<el-input-number v-model="form.sales_commission" :precision="2" :step="10" :max="99999" :min="0"></el-input-number>
					</el-form-item>
					<el-form-item label="服务项目">
						<div>
							<el-button icon="el-icon-plus" @click="openItems(1)">添加品牌服务</el-button>
							<el-button icon="el-icon-plus" @click="openItems(2)">添加自营服务</el-button>
						</div>
						<div class="items auto-height margin-top">
							<div class="item not-select" v-for="item in serveItems" :key="item.id">
								<el-image :src="item.cover" fit="cover"></el-image>
								<div class="cont">
									<div class="tit line-2">
										<span v-if="item.type==1" class="text-blue">[品牌]</span>
										<span v-if="item.type==2" class="text-green">[自营]</span>
										{{item.name}}
									</div>
									<div class="price-box">
										<div class="price">￥{{item.price}}</div>
										<div class="count">
											<i class="el-icon-remove" @click.stop="countChange(-1,item)"></i>
											<el-input v-model.number="item.number" size="mini" @click.native.stop=""></el-input>
											<i class="el-icon-circle-plus" @click.stop="countChange(1,item)"></i>
										</div>
									</div>
								</div>
								<i class="el-icon-delete text-grey" @click.stop="delItem(item)"></i>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="商品">
						<div>
							<el-button icon="el-icon-plus" @click="openGoodsItems(1)">添加品牌商品</el-button>
							<el-button icon="el-icon-plus" @click="openGoodsItems(2)">添加自营商品</el-button>
						</div>
						<div class="items auto-height margin-top">
							<div class="item not-select" v-for="item in goodsItems" :key="item.id">
								<el-image :src="item.cover" fit="cover"></el-image>
								<div class="cont">
									<div class="tit line-2">
										<span v-if="item.type==1" class="text-blue">[品牌]</span>
										<span v-if="item.type==2" class="text-green">[自营]</span>
										{{item.name}}
										<div class="text-blue">[{{item.sku||'默认'}}]</div>
									</div>
									<div class="price-box">
										<div class="price">￥{{item.price}}</div>
										<div class="count">
											<i class="el-icon-remove" @click.stop="countChange(-1,item)"></i>
											<el-input v-model.number="item.number" size="mini" @click.native.stop=""></el-input>
											<i class="el-icon-circle-plus" @click.stop="countChange(1,item)"></i>
										</div>
									</div>
								</div>
								<i class="el-icon-delete text-grey" @click.stop="delGoodsItem(item)"></i>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="上架">
						<el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<!-- 选择服务项目 -->
		<el-dialog title="选择服务项目" :visible.sync="itemDialog.dialogVisible" width="800px" :append-to-body="true"
		:close-on-click-modal="false">
			<div class="items" v-loading="itemDialog.loading">
				<div class="item not-select" :class="{cur:item.cur}" v-for="item in itemDialog.items" @click="item.cur = !item.cur" :key="item.id">
					<el-image :src="item.cover" fit="cover"></el-image>
					<div class="cont">
						<div class="tit line-2">{{item.name}}</div>
						<div class="price-box">
							<div class="price" v-if="item.price">￥{{item.price}}</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="itemDialog.dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addItem()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 选择商品项目 -->
		<el-dialog title="选择商品项目" :visible.sync="itemGoodsDialog.dialogVisible" width="800px" :append-to-body="true"
		:close-on-click-modal="false">
			<div class="items" v-loading="itemGoodsDialog.loading">
				<div class="item not-select" :class="{cur:item.cur}" v-for="item in itemGoodsDialog.items" @click="item.cur = !item.cur" :key="item.id">
					<el-image :src="item.cover" fit="cover"></el-image>
					<div class="cont">
						<div class="tit line-2">{{item.name}}
							<div class="text-blue">[{{item.sku||'默认'}}]</div>
						</div>
						<div class="price-box">
							<div class="price" v-if="item.price">￥{{item.price}}</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="itemGoodsDialog.dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addGoodsItem()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import rules from '../../common/common.rules.js'
	export default {
		data() {
			return {
				rules,
				groups: [],
				loading: false,
				form: {
					id: this.$route.params.id, // int 服务ID
					name: '', // string 规格名称
					set_meal_id: undefined, // int 套餐ID
					price: '', // 价格
					sales_commission: '', // 销售提成
					notice: '', // 套餐规格须知
					serve: {
						platform: [],
						self: []
					}, // 包含的服务
					goods: {
						platform: [],
						self: []
					}, // 包含的商品
					status: 1, // 上架
				},
				// 弹框
				itemDialog: {
					loading: false,
					dialogVisible: false,
					type: 1, // 1 品牌服务，2 自营服务
					items: [],
				},
				itemGoodsDialog: {
					loading: false,
					dialogVisible: false,
					type: 1, // 1 品牌商品，2 自营商品
					items: [],
				}
			};
		},
		computed: {
			serveItems() {
				return [...this.form.serve.platform, ...this.form.serve.self]
			},
			goodsItems() {
				return [...this.form.goods.platform, ...this.form.goods.self]
			},
			title() {
				if (this.form.id) {
					return '编辑套餐'
				} else {
					return '新增套餐'
				}
			}
		},
		mounted() {
			// 获取套餐
			this.$api.group.getGroups({
				page: 1,
				page_size: 999,
				type: 1
			}).then(res => {
				this.groups = res.data.data
			})
			if (this.form.id) {
				this.$api.group.getGroupItem({id:this.form.id}).then(res=>{
					this.form = res.data;
				})
			}
		},
		methods: {
			onSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						// 构建接口需要的数据（将产品和服务中除id和number，其余字段去掉）
						let newform = JSON.parse(JSON.stringify(this.form));
						newform.serve.platform = newform.serve.platform.map(item => {
							return {
								id: item.id,
								number: item.number
							}
						})
						newform.serve.self = newform.serve.self.map(item => {
							return {
								id: item.id,
								number: item.number
							}
						})
						newform.goods.platform = newform.goods.platform.map(item => {
							return {
								id: item.id,
								number: item.number
							}
						})
						newform.goods.self = newform.goods.self.map(item => {
							return {
								id: item.id,
								number: item.number
							}
						})
						const loading = this.$loading();
						this.$api.group.saveItem(newform).then(res => {
							loading.close();
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`保存成功`);
								this.$router.back();
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							loading.close();
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
			// ================================服务项目
			// 添加到服务项目
			addItem() {
				let selectedItems = this.itemDialog.items.filter(item => {
					return item.cur;
				}).map(item => {
					return {
						id: item.id, // 是 number 服务产品ID
						name: item.name, // 是 string 服务名称
						cover: item.cover,
						type: item.type,
						price: item.price, // 是 number 价格
						number: 10 // 是 number 数量
					}
				})
				if (this.itemDialog.type == 2) {
					// 添加到自营列表
					this.form.serve.self.push(...selectedItems)
				} else {
					// 添加到品牌列表
					this.form.serve.platform.push(...selectedItems)
				}
				this.itemDialog.dialogVisible = false;
			},
			// 修改次数
			countChange(num, item) {
				item.number += num;
				item.number = item.number < 1 ? 1 : item.number;
			},
			delItem(item) {
				let i = this.form.serve.platform.findIndex(p => p == item)
				if (~i) {
					this.form.serve.platform.splice(i, 1);
				}
				i = this.form.serve.self.findIndex(p => p == item)
				if (~i) {
					this.form.serve.self.splice(i, 1);
				}
			},
			// 打开选择服务弹框
			openItems(type) {
				this.itemDialog.type = type;
				this.itemDialog.loading = true;
				if (type == 1) {
					// 查询品牌项目
					this.$api.project.getBrandProjects({
						page: 1,
						page_size: 999
					}).then(res => {
						this.itemDialog.items = res.data.data.map(item => {
							return {
								id: item.id,
								name: item.serve_name,
								price: item.price,
								cover: item.cover,
								type: 1,
								cur: false
							}
						});
						this.itemDialog.loading = false;
					});
				} else {
					// 查询自营项目
					this.$api.project.getProjects({
						page: 1,
						page_size: 999
					}).then(res => {
						this.itemDialog.items = res.data.data.map(item => {
							return {
								id: item.id,
								name: item.server_name,
								price: item.current_price,
								cover: item.cover,
								type: 2,
								cur: false
							}
						});
						this.itemDialog.loading = false;
					})
				}
				this.itemDialog.dialogVisible = true
			},
			// ==========================================商品项目
			// 添加到商品项目
			addGoodsItem() {
				let selectedItems = this.itemGoodsDialog.items.filter(item => {
					return item.cur;
				}).map(item => {
					return {
						id: item.id, // 是 number 商品产品ID
						name: item.name, // 是 string 商品名称
						sku: item.sku,
						cover: item.cover,
						type: item.type,
						price: item.price, // 是 number 价格
						number: 10 // 是 number 数量
					}
				})
				if (this.itemGoodsDialog.type == 2) {
					// 添加到自营列表
					this.form.goods.self.push(...selectedItems)
				} else {
					// 添加到品牌列表
					this.form.goods.platform.push(...selectedItems)
				}
				this.itemGoodsDialog.dialogVisible = false;
			},
			delGoodsItem(item) {
				let i = this.form.goods.platform.findIndex(p => p == item)
				if (~i) {
					this.form.goods.platform.splice(i, 1);
				}
				i = this.form.goods.self.findIndex(p => p == item)
				if (~i) {
					this.form.goods.self.splice(i, 1);
				}
			},
			// 打开选择服务弹框
			openGoodsItems(type) {
				this.itemGoodsDialog.type = type;
				this.itemGoodsDialog.loading = true;
				if (type == 1) {
					// 查询品牌商品
					this.$api.goods.getBrandGoods({
						page: 1,
						page_size: 999
					}).then(res => {
						this.itemGoodsDialog.items = [];
						res.data.data.forEach(item => {
							item.specs.forEach(spec => {
								let obj = {
									id: spec.product_specs_id,
									name: item.name,
									sku: spec.sku_values,
									price: spec.current_price,
									cover: spec.image||item.cover,
									type: 1,
									cur: false
								}
								this.itemGoodsDialog.items.push(obj)
							})
						});
						this.itemGoodsDialog.loading = false;
					});
				} else {
					// 查询自营商品
					this.$api.goods.getGoods({
						page: 1,
						page_size: 999
					}).then(res => {
						this.itemGoodsDialog.items = [];
						res.data.data.forEach(item => {
							let obj = {
								id: item.id,
								name: item.name,
								price: item.current_price,
								cover: item.cover,
								type: 2,
								cur: false
							}
							this.itemGoodsDialog.items.push(obj)
						});
						this.itemGoodsDialog.loading = false;
					})
				}
				this.itemGoodsDialog.dialogVisible = true
			},
		},
	};
</script>

<style scoped lang="scss">
	.form_box {

		.el-input,
		.el-textarea {
			width: 500px;
		}
	}

	.items {
		display: flex;
		flex-wrap: wrap;
		height: 500px;
		overflow: auto;

		&.auto-height {
			height: auto;

			.item {
				width: 400px;

				.cont {
					.tit {
						height: 55px;
					}
				}
			}

		}

		.item {
			width: 300px;
			margin: 0 20px 20px 0;
			height: 90px;
			border: 2px solid #ccc;
			border-radius: 10px;
			padding: 10px;
			display: flex;
			cursor: pointer;

			&.cur {
				border: 2px solid var(--green);
			}

			.cont {
				flex: 1;
				margin-left: 10px;
				position: relative;

				.tit {
					height: 70px;
					line-height: 20px;
					font-size: 16px;
				}

				.price-box {
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;


					.price {
						font-size: 20px;
						line-height: 20px;
						color: var(--orange);
					}

					.count {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 24px;
						color: var(--blue);

						i {
							// font-size: 30px;
							cursor: pointer;
						}

						.el-input {
							width: 43px;
							margin: 0 5px;
							font-size: 20px;
						}

						.el-input>>>input {
							padding: 0;
							text-align: center;
						}

					}
				}

			}

			.el-image {
				width: 90px;
				height: 90px;
				border-radius: 5px;
			}
		}
	}
</style>
